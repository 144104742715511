import React from 'react'
import { Helmet } from 'react-helmet'
import HeroImage from '../components/hero/heroImage';

const Kontakt = ({...props}) => {
  const data = props.data.contentfulKontakt;

    return ( <>
        <div>
          <Helmet title={"Title"} />
          {data.image ?<HeroImage style={{marginBottom:80}} image={data.image} title={data.title} />:
          <div className="wrapper">
            <h1>Kontakt</h1>
          </div>}
        </div>
      </> );
}
 
export default Kontakt;

export const pageQuery = graphql`
  query KontaktQuery {
    contentfulKontakt {
      title
      image {
        fluid(maxWidth: 1180,quality: 90,  background: "rgb:000000") {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`